import { XMarkIcon } from '@heroicons/react/24/solid'
import YouTube from 'react-youtube'

const VideoOverlay = ({ visible, setVisible }) => {
  const videoId = 'LxIOe79-oOw'

  return (
    visible && (
      <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
        {/* md:max-w takes small height screens like NestHub into account */}
        <div className="relative w-full sm:max-w-[750px] md:max-w-[600px] lg:max-w-[920px] px-5 md:px-0">
          <button className="absolute right-0 md:right-[-50px] top-[-50px] md:top-0 z-10 mr-[20px] md:mr-0 transform transition-transform duration-200 hover:scale-110">
            <XMarkIcon
              onClick={() => setVisible(false)}
              className="h-[40px] w-[40px] text-white bg-red-700 hover:bg-red-600 rounded-full p-2"
            />
          </button>

          <div className="relative pb-[56.25%] h-0 overflow-hidden">
            <YouTube
              iframeClassName="absolute top-0 left-0 w-full h-full"
              videoId={videoId}
              className="absolute top-0 left-0 w-full h-full"
            />
          </div>
        </div>
      </div>
    )
  )
}

export default VideoOverlay
