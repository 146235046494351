import React from 'react';

const AndroidIcon = ({ className }) => (
  <svg
  className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 803.2 803.2"
    style={{ enableBackground: '0 0 803.2 803.2' }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`.st0 { fill: #FFFFFF; }`}
    </style>
    <path d="M0 0h803.2v803.2H0z"/>
    <path d="M248.6 537.5c0 16.8 13.6 30.3 30.4 30.3h32.4v77.7c0 18.2 14.7 33 33 33h1.4c18.2 0 33-14.9 33-33v-77.7h45.4v77.7c0 18.2 14.9 33 33 33h1.4c18.2 0 33-14.9 33-33v-77.7h32.5c16.7 0 30.4-13.5 30.4-30.3V310.6H248.6v226.9zM232.8 338.3c0-18.1-14.9-33-33-33h-1.4c-18.2 0-33 14.9-33 33v143.8c0 18.1 14.8 33 33 33h1.4c18.2 0 33-14.8 33-33V338.3zM637.8 338.3c0-18.1-14.8-33-33-33h-1.4c-18.2 0-33 14.9-33 33v143.8c0 18.1 14.9 33 33 33h1.4c18.2 0 33-14.8 33-33V338.3zM555.5 287.9c0-49.2-31.4-92.1-77.8-114.9l25.8-39.8c1.6-2.4.9-5.9-1.6-7.5-2.5-1.8-5.9-1.1-7.5 1.6l-26.8 41.1c-20-8.2-42.4-12.8-66-12.8s-46 4.6-66 12.8l-26.7-41.1c-1.6-2.6-5.1-3.3-7.6-1.6-2.5 1.6-3.2 5.1-1.6 7.5l25.7 39.8c-46.4 22.8-77.8 65.7-77.8 114.9 0 3 .3 6 .5 9h307c.2-3.1.5-6 .4-9zm-225.1-43.7c-8.2 0-14.8-6.6-14.8-14.8 0-8.3 6.6-14.7 14.8-14.7s14.8 6.4 14.8 14.7c0 8.2-6.6 14.8-14.8 14.8zm142.4 0c-8.2 0-14.8-6.6-14.8-14.8 0-8.3 6.6-14.7 14.8-14.7s14.7 6.4 14.8 14.7c0 8.2-6.6 14.8-14.8 14.8z" className="st0"/>
  </svg>
)

export default AndroidIcon;