import React from 'react'
import Button from '../Button'
import { PlayCircleIcon } from '@heroicons/react/24/solid'
import { useTranslation } from '../../utils/localization'
import { useState } from 'react'
import VideoOverlay from '../VideoOverlay'

const HowItWorks = () => {
  const t = useTranslation()
  const [showVideo, setShowVideo] = useState(false)

  return (
    <div className="flex flex-col md:w-full items-center justify-center gap-12">
      <Button
        className="w-[230px] md:w-full md:max-w-[340px] h-[50px] text-white bg-aldoo_black rounded-full flex items-center justify-center gap-2"
        onClick={() => setShowVideo(!showVideo)}
      >
        {t`See how it works`}
        <PlayCircleIcon className="w-8 h-8 text-red-500" />
      </Button>
      <VideoOverlay visible={showVideo} setVisible={setShowVideo} />
    </div>
  )
}

export default HowItWorks
