import React from 'react';

const WebIcon =  ({ className }) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    x="0"
    y="0"
    viewBox="0 0 800 800"
    style={{ enableBackground: 'new 0 0 800 800' }}
  >
    <style type="text/css">
      {`.st0 { fill: #FFFFFF; }`}
    </style>
    <g id="web">
      <path d="M0 0h800v800H0z"/>
      <g id="globe">
        <path d="M479.1 103.4c18.8 16.4 35.9 38.8 50.8 66.6 8.7 16.4 16.4 34.2 23 53.1 35.7-8.1 62.5-17.4 79.1-23.9-39.7-45.8-92.7-79.8-152.9-95.8zM400 93c-41.2 0-80.4 31-110.5 87.3-7.9 14.8-14.9 30.9-21 48 36.9 7 81.1 11.9 131.5 11.7 50.5-.2 94.6-5.5 131.3-12.5-6-16.8-12.9-32.6-20.7-47.2C480.4 124 441.2 93 400 93zM246.8 223.8c6.7-19.2 14.4-37.3 23.3-53.9 14.9-27.8 32.1-50.2 50.8-66.6-60.5 16.1-113.7 50.3-153.4 96.4 18 7.2 44.8 16.4 79.3 24.1zM261.6 550.5c38.9-7.5 85.2-12.7 138.4-12.5 53.4.2 99.7 5.8 138.1 13.2 12.7-42.5 19.8-90.3 20.7-140.2H241.1c.9 49.6 8 97.1 20.5 139.5zM538.2 248.8c-38.4 7.5-84.7 13-138.1 13.2h-3.2c-51.9 0-97.2-5.2-135.3-12.5-12.5 42.3-19.6 89.9-20.5 139.5h317.8c-.9-49.9-8.1-97.7-20.7-140.2zM93.2 389h125.9c.9-51 8.1-100 20.9-144-37.3-8.5-66.5-18.8-86.9-27.3C117.4 266 95.5 325 93.2 389zM706.8 411H580.9c-.9 51.3-8.2 100.6-21.1 144.8 39.5 9.1 68.8 19.7 86.7 27.1 35.9-48.4 58-107.6 60.3-171.9zM153.1 582.3c20.4-8.5 49.6-18.8 86.9-27.3-12.8-43.9-20-93-20.9-144H93.2c2.3 64 24.2 123 59.9 171.3zM646.4 217.1c-17.9 7.5-47.2 18.1-86.7 27.1 13 44.1 20.2 93.5 21.1 144.8h125.9c-2.2-64.3-24.3-123.5-60.3-171.9zM553 577c-6.6 18.9-14.3 36.7-23 53.1-14.9 27.8-32.1 50.2-50.8 66.6 60.2-16.1 113.2-50 152.9-95.8-16.6-6.6-43.5-15.9-79.1-23.9zM400 707c41.2 0 80.4-31 110.5-87.3 7.8-14.6 14.7-30.4 20.7-47.2-36.7-7-80.8-12.2-131.3-12.5-50.4-.2-94.6 4.8-131.5 11.7 6.1 17.1 13.1 33.1 21 48C319.6 676 358.8 707 400 707zM320.9 696.6c-18.8-16.4-35.9-38.8-50.8-66.6-8.9-16.6-16.7-34.6-23.3-53.8-34.5 7.8-61.2 16.9-79.3 24 39.7 46.1 92.9 80.3 153.4 96.4z" className="st0"/>
      </g>
    </g>
  </svg>
)

export default WebIcon;