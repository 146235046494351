import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Typography from '../../components/Typography'
import { useTranslation } from '../../utils/localization'

// SVG Star Icon Component
const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="w-6 h-6"
    style={{ fill: '#fb923c' }} // orange-400
  >
    <path d="M12 .587l3.668 7.431L24 9.577l-6 5.848L19.335 24 12 20.2 4.665 24 6 15.425 0 9.577l8.332-1.559z" />
  </svg>
)

// Review Data
const reviews = [
  {
    id: 1,
    review:
      'This PDF translation app is a game-changer! The accuracy is incredible, and the additional tools are super handy.',
    user: 'Emily R.',
  },
  {
    id: 2,
    review:
      'I can easily translate my office documents with this app. The high accuracy and online viewer make it indispensable.',
    user: 'James B.',
  },
  {
    id: 3,
    review:
      'Highly accurate translations and a bunch of useful tools. This app is perfect for handling all my PDF needs.',
    user: 'Sophia L.',
  },
  {
    id: 4,
    review:
      'The online viewer feature is fantastic! I can translate and view my PDFs directly in the app. 5 stars!',
    user: 'Michael K.',
  },
  {
    id: 5,
    review:
      'This app translates my PDFs with exceptional accuracy and provides additional tools that simplify my workflow.',
    user: 'Olivia W.',
  },
  {
    id: 6,
    review:
      'Amazing app! It translates office documents perfectly and offers useful tools and an online viewer for easy access.',
    user: 'Daniel M.',
  },
  {
    id: 7,
    review:
      'The high accuracy of the translations and the extra features like the online viewer make this app a must-have.',
    user: 'Mia H.',
  },
  {
    id: 8,
    review:
      'I love this app! The translations are spot-on, and the additional tools and online viewer are very convenient.',
    user: 'Lucas P.',
  },
  {
    id: 9,
    review:
      'Top-notch PDF translation app with high accuracy. The extra tools and online viewer feature are incredibly useful.',
    user: 'Ava T.',
  },
  {
    id: 10,
    review:
      'Perfect for translating office documents! The accuracy is high, and the online viewer and tools are a great bonus.',
    user: 'Ethan J.',
  },
]

// Review Component
const Review = ({ review, user }) => {
  const t = useTranslation()

  return (
    <motion.div
      className="flex flex-col items-center justify-center text-center w-full md:w-[800px] h-[300px] px-8"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex justify-center  w-full md:w-[800px] gap-3 mb-6">
        {[...Array(5)].map((_, index) => (
          <StarIcon key={index} />
        ))}
      </div>
      <Typography className="text-lg sm:text-xl md:text-2xl text-text font-bold text-center md:w-[700px]">
        {t(review)}
      </Typography>
      <Typography className="text-text text-center mt-4">
        {t(user)}
      </Typography>
    </motion.div>
  )
}

// Animated Reviews Component
const AnimatedReviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length)
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  const t = useTranslation()

  return (
    <div className="flex justify-center items-center w-full h-[320px] md:h-[400px] bg-canvas">
      <div className="overflow-hidden w-full md:w-[800px] px-4 md:px-0 mx-auto">
          <Review
            key={reviews[currentIndex].id}
            review={reviews[currentIndex].review}
            user={reviews[currentIndex].user}
          />
      </div>
    </div>
  )
}

export default AnimatedReviews
