import React from 'react'
import Typography from '../Typography'
import Button from '../../components/Button'
import { useTranslation } from '../../utils/localization'
import IosIcon from '../PlatformIcons/iosIcon'
import AndroidIcon from '../PlatformIcons/androidIcon'
import WebIcon from '../PlatformIcons/webIcon'

const DeviceSection = () => {
  const t = useTranslation()

  return (
    <div className="flex flex-col md:flex-row w-full items-center justify-center bg-paper gap-20 md:gap-10 lg:gap-20 px-8 md:px-4 py-[60px] md:py-0 mx-auto">
      {/* Left Section */}
      <div className="flex flex-col w-full sm:max-w-[560px] md:w-[45%] md:h-[600px] items-start md:items-end justify-between space-y-8 md:py-[153px] lg:py-[100px]">
        <div
          className="text-left md:text-right text-[26px] sm:text-[34px] md:text-[44px] lg:text-[54px] font-extrabold leading-tight px-8 md:px-0"
        >
          <h2
            className="text-text"
          >
            {t`Translate`}
          </h2>
          <h2 className="text-red-500">
            {t`On Any Device`}
          </h2>
        </div>

        {/* Platform Icons */}
        <div className="gap-5 flex items-center justify-center md:justify-end w-full">
          {/* iOS */}
          <div className="w-[150px] rounded-xl overflow-hidden hover:border-2 hover:border-link">
            <a
              href="https://apps.apple.com/us/app/pdf-translator-pro/id1609232374"
              aria-label="Download on the App Store"
              target="_blank"
            >
              <IosIcon className="w-full h-full" />
            </a>
          </div>
          {/* Android */}
          <div className="w-[150px] rounded-xl overflow-hidden hover:border-2 hover:border-link">
            <a
              href="https://play.google.com/store/apps/details?id=com.aldoo.pdftranslatorpro&hl=en&gl=US"
              aria-label="Get it on Google Play"
              target="_blank"
            >
              <AndroidIcon className="w-full h-full" />
            </a>
          </div>
          {/* Web */}
          <div className="w-[150px] rounded-xl overflow-hidden hover:border-2 hover:border-link">
            <a aria-label="Translate on Web" href="/translate">
              <WebIcon className="w-full h-full" />
            </a>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex w-full sm:w-[560px] md:w-[55%] md:h-[600px] items-center justify-start">
        <img
          src="/static/images/home_intro.webp"
          alt="Document management on various devices"
          className="w-full sm:w-[520px] md:w-[480px] lg:w-[650px] h-auto mx-auto md:mx-0"
        />
      </div>
    </div>
  )
}

export default DeviceSection
