import React from 'react';

const IosIcon = ({ className }) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2500 2500"
    style={{ enableBackground: 'new 0 0 2500 2500' }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`.st0 { fill: #FFFFFF; }`}
    </style>
    <path d="M0,0h2500v2500H0V0z" />
    <path
      className="st0"
      d="M1043.9,726c-77.7,1-144.7,16.3-210.5,49c-168,83.7-264.9,259.8-264.9,481.8
        c0,222.5,95.4,393.5,263.9,473.4c45.5,21.6,91.2,33.4,160.5,41.6c35.1,1.4,67.9,3.9,102,0
        c69.3-8.2,114.9-20,160.5-41.6c168.6-80,264-250.9,264-473.4c0-222-97-398.2-264.9-481.8
        C1188.6,742.3,1121.7,727,1043.9,726L1043.9,726z M1110.8,882.5c100.8,26.9,174,117.9,202,250.6
        c19.7,93.2,14.3,200.9-13.9,285.7c-43.9,132.1-139.2,206.7-255,205.5c-115.8,1.2-211.1-73.4-255-205.5
        c-28.2-84.8-33.6-192.5-13.9-285.7c28-132.7,101.2-223.7,202.1-250.6C1022.8,872.8,1068.4,874.5,1110.8,882.5L1110.8,882.5z
        M374.3,726c-55.7,0-100.8,45.1-100.8,100.8s45.1,100.8,100.8,100.8s100.8-45.1,100.8-100.8l0,0
        C475.1,771.1,430,726,374.3,726z M280.1,1033.6V1774h188.4v-740.4H280.1z M1793.3,1772.5c-80.7-13.8-158.4-24.5-224.3-62
        c-0.9-0.8,38.9-149.4,40.7-152.1c105.9,36,255,86.4,355.6,40.2c54.8-27.4,81.8-78.1,72.7-136.6
        c-10.2-65.8-55.7-103.6-180.7-150.4c-139.4-52.1-221.1-116.2-254.5-199.4c-16.4-41-21.9-93.1-14.5-138.6
        c20.6-126.7,119.2-214.8,270.7-241.7c43.9-7.8,137.7-7.9,183.9-0.2c66.5,11.1,142.8,36.3,142.8,47.1c0,4-39,141.4-41,144.5
        c-65.8-26.1-139.6-44.7-202-45.1c-88.3-1.1-184.1,51.3-167.2,143.9c12,58,49,86.4,185.3,141.8
        c100.4,40.8,148.6,69,189.9,110.9c57.4,57.3,75.5,124.5,75.9,191.2c0.2,55.5-5,81.4-24.8,124.2
        c-48.1,104.1-145.9,164.4-293.5,181C1880.7,1774.3,1815.6,1775,1793.3,1772.5L1793.3,1772.5z"
    />
  </svg>
)

export default IosIcon;